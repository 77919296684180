<template>
  <div id="home-page">
    <hero />
    <map-section />
    <callouts-section />
    <selling-buying-callouts />
  </div>
</template>
<script>
import Hero from "@/components/Home/HeroSection.vue";
import MapSection from "@/components/Home/MapSection.vue";
import CalloutsSection from "@/components/Home/CalloutsSection.vue";
import SellingBuyingCallouts from "@/components/Home/SellingBuyingCallouts.vue";
export default {
  components: {
    Hero,
    MapSection,
    CalloutsSection,
    SellingBuyingCallouts,
  },
};
</script>
<style scoped>
</style>