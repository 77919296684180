<template>
  <textarea
    id="additional-details-text-area"
    @input="input"
    placeholder="Start typing a message!"
  />
</template>
<script>
export default {
  data() {
    return {};
  },
  computed: {},
  methods: {
    input(val) {
      this.$emit("additional-detail-input", val.target.value);
    },
  },
  created() {},
  components: {},
  props: {},
  emits: {
    "additional-detail-input": null,
  },
};
</script>
<style scoped>
#additional-details-text-area {
  width: 100%;
  height: 400px;
  padding: 10px;
  box-sizing: border-box;
  background-color: #292929;
  border: 1px solid #f2efeb;
  border-radius: 8px;
  resize: none;
  color: #f2efeb;
  font-family: Montserrat, sans-serif;
  font-size: 1rem;
}

#additional-details-text-area:focus {
  outline: none;
  filter: drop-shadow(0px 2px 2px #f2efeb);
}
@media (min-width: 700px) {
  #additional-details-text-area {
  width: 600px;
  height: 400px;
  padding: 10px;
}

}

</style>