<template>
  <div id="lauryn-coleman-header">
    <router-link class="header-logo" to="/">Lauryn Coleman</router-link>
    <div class="divider"></div>
    <div id="header-links">
      <router-link to="/buyers" class="header-link">Buy</router-link>
      <router-link to="/sellers" class="header-link">Sell</router-link>
      <router-link to="/contact" class="header-link">Contact</router-link>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {
    let app = document.getElementById("app");
    let header = document.getElementById("lauryn-coleman-header");
    window.addEventListener("scroll", ({ target }) => {
      let top = target.scrollingElement.scrollTop === 0;
      if (!top) {
        header.classList.add("active");
      } else {
        header.classList.remove("active");
      }
    });
  },
};
</script>
<style scoped>
#lauryn-coleman-header {
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  z-index: 3;
  background-color: #f2efeb;
  transition: all 0.5s ease;
}

.divider {
  width: 100%;
  max-height: 1px;
  border: 1px solid #292929;
  margin: 0 20px;
  transition: border 0.5s ease;
}

.header-logo,
.header-link {
  font-family: Montserrat, sans-serif;
  font-size: 1rem;
  text-transform: uppercase;
  white-space: nowrap;
  margin: 0 10px;
}

.active {
  background-color: #292929 !important;
  color: #f2efeb;
  border-bottom: 1px solid #f2efeb;
}

.active > .divider {
  border: 1px solid #f2efeb !important;
}
@media (min-width: 500px) {
  .header-link,
  .header-logo {
    font-size: 1rem;
  }
  #lauryn-coleman-header {
    flex-direction: row;
  }
}
</style>