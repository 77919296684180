<template>
  <div id="lauryn-coleman-person-contact-form">
    <Input
      heading="First Name"
      placeholder="First Name"
      @scs-input="firstNameInput"
    />
    <Input
      heading="Last Name"
      placeholder="Last Name"
      @scs-input="lastNameInput"
    />
    <Input
      heading="Phone Number"
      placeholder="Phone Number"
      @scs-input="phoneNumberInput"
    />
    <Input heading="Email" placeholder="Email" @scs-input="emailAddressInput" />
    <Input
      v-if="messageField"
      heading="Message"
      :textarea="true"
      placeholder="Start Typing..."
      @scs-input="messageInput"
    />
  </div>
</template>
<script>
import Input from "./Input.vue";
export default {
  methods: {
    firstNameInput(val) {
      this.$emit("first-name-input", val);
    },
    lastNameInput(val) {
      this.$emit("last-name-input", val);
    },
    phoneNumberInput(val) {
      this.$emit("phone-number-input", val);
    },
    emailAddressInput(val) {
      this.$emit("email-address-input", val);
    },
    messageInput(val) {
      this.$emit("message-input", val);
    },
  },
  components: { Input },
  props: { messageField: { type: Boolean } },
  emits: {
    "first-name-input": null,
    "last-name-input": null,
    "phone-number-input": null,
    "email-address-input": null,
    "message-input": null,
  },
};
</script>
<style scoped>
</style>