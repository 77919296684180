<template>
  <div id="general-contact-form">
    <FormSuccess v-if="success" />
    <h1 v-if="!success">Let's Chat!</h1>
    <div id="contact-form-body" v-if="!success">
      <div id="contact-info">
        <p>Lauryn Coleman</p>
        <p>314-610-7887</p>
        <p>lauryn@thelauryncoleman.com</p>
        <br />
        <br />
        <p>C4 Realty Group</p>
        <p>636-543-5943</p>
      </div>
      <div class="divider"></div>
      <div class="form">
        <ContactFormPerson
          :messageField="true"
          @first-name-input="firstNameInput"
          @last-name-input="lastNameInput"
          @email-address-input="emailAddressInput"
          @phone-number-input="phoneNumberInput"
          @message-input="messageInput"
        />
        <ButtonVue
          :processing="processing"
          text="Submit"
          id="contact-submission-button"
          @click="submit"
        />
      </div>
    </div>
  </div>
</template>
<script>
import ContactFormPerson from "@/components/Shared/ContactFormPerson.vue";
import ButtonVue from "../components/Shared/Button.vue";
import axios from "axios";
import FormSuccess from "@/components/Shared/FormSuccess.vue";

export default {
  data() {
    return {
      success: false,
      processing: false,
      form: {
        contact_first_name: undefined,
        contact_last_name: undefined,
        contact_email: undefined,
        contact_phone_number: undefined,
        message: undefined,
      },
    };
  },
  computed: {},
  methods: {
    additionalDetailInput(val) {
      this.form.message = val;
    },
    firstNameInput(val) {
      this.form.contact_first_name = val;
    },
    lastNameInput(val) {
      this.form.contact_last_name = val;
    },
    phoneNumberInput(val) {
      this.form.contact_phone_number = val;
    },
    emailAddressInput(val) {
      this.form.contact_email = val;
    },
    messageInput(val) {
      this.form.message = val;
    },
    async submit() {
      this.processing = true;
      await axios
        .put(
          "https://8vti1zuhk6.execute-api.us-east-1.amazonaws.com/forms/general",
          this.form
        )
        .then((res) => {
          console.log(res);
        })
        .catch((e) => {
          console.log(e);
        });
      this.success = true;
      setTimeout(() => {
        this.$router.push("/");
        console.log(document);
        document.body.scrollTop = document.documentElement.scrollTop = 0;
      }, 2000);
    },
  },
  created() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  },
  components: {
    ContactFormPerson,
    ButtonVue,
    FormSuccess,
  },
  props: {},
  emits: {},
};
</script>
<style scoped>
#general-contact-form {
  min-height: 95vh;
  padding: 50px;
  box-sizing: border-box;
  width: 100vw;
  background-color: #292929;
  color: #f2efeb;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

#contact-form-body {
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-evenly;
  align-items: center;
}

.divider {
  width: 100%;
  margin: 25px;
  border: 1px solid #f2efeb;
}
#contact-info {
  margin: 25px;
}
#contact-info > p {
  font-size: 0.9rem;
  text-align: right;
  text-transform: uppercase;
  font-weight: 300;
  margin: 5px;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
}

#contact-submission-button {
  background-color: #f2efeb;
  color: #292929;
  margin: 10px;
}
@media (min-width: 800px) {
  #general-contact-form {
    min-height: 95vh;
    padding: 50px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }

  #contact-form-body {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }

  #contact-info {
    margin: 0;
  }
  .divider {
    height: 400px;
    width: unset;
    margin: unset;
  }
}
</style>