<template>
  <div :class="{ 'count-button': true, selected: selected }">
    <p>{{ count }}</p>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {},
  methods: {},
  created() {},
  components: {},
  props: {
    count: {
      type: String,
    },
    selected: {
      type: Boolean,
    },
  },
  emits: {},
};
</script>
<style scoped>
.count-button {
  border: 2px solid #f2efeb;
  border-radius: 10px;
  margin: 5px;
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
p {
  font-size: 2rem;
  color: #f2efeb;
  margin: 0;
}

.selected {
  background-color: #f2efeb;
}

.selected > p {
  color: #292929;
}
@media (min-width: 700px) {
  .count-button {
    padding: 40px;
    width: 50px;
    height: 50px;
  }
  p {
    font-size: 2rem;
  }
}
</style>