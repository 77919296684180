<template>
  <div id="app">
    <Header />
    <router-view />
    <Footer />
  </div>
</template>
<script>
import Footer from "@/components/Footer.vue";
import Header from "@/components/Header.vue";
export default {
  data() {
    return {};
  },
  computed: {},
  methods: {},
  created() {},
  components: {
    Header,
    Footer,
  },
  props: {},
  emits: {},
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Bodoni+Moda:opsz,wght@6..96,400;6..96,500;6..96,600;6..96,700;6..96,800;6..96,900&family=Montserrat:wght@300;400;500;600;700&display=swap");
body {
  margin: 0px;
  padding: 0px;
  overflow-y: scroll;
}
#app {
  width: 100vw;
  padding: 50px 0 0;
  box-sizing: border-box;
  top: 0;
  left: 0;
  overflow-x: hidden;
  margin: 0;
  background-color: #f2efeb;
  color: #292929;
  font-weight: 500;
  font-family: Montserrat, serif;
}
a {
  text-decoration: none;
  color: unset;
}
a:hover {
  filter: drop-shadow(0px 0px 0px #292929);
}
</style>