<template>
  <div id="seller-page">
    <Hero />
    <ValueDetailsVue />
    <Form />
  </div>
</template>
<script>
import Form from "@/components/Buyer/ContactForm/Form.vue";
import Hero from "@/components/Buyer/Hero.vue";
import ValueDetailsVue from "../components/Buyer/ValueDetails.vue";

export default {
  data() {
    return {};
  },
  computed: {},
  methods: {},
  created() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  },
  components: {
    Form,
    Hero,
    ValueDetailsVue,
  },
  props: {},
  emits: {},
};
</script>
<style scoped>
</style>