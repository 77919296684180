<template>
  <div id="map-wrapper">
    <div id="scs-live-map"></div>
  </div>
</template>
<script>
import maplibregl from "maplibre-gl";
export default {
  data() {
    return {
      map: undefined,
    };
  },
  computed: {},
  methods: {},
  mounted() {
    const apiKey =
      "v1.public.eyJqdGkiOiIxMjE0MDMyYi0yOWVhLTRiMmEtYjk5YS05ZTBhYzU3YTE0NmYifRTUBINsMQ-V6A57SORv0mWJm0jR7WFwtFXuLMaqh51fYehMA5i0zvWav7megnxTC13ysck6TF6YkJJw-xtCK1Alt3o91TwqvocBHWOcqz9Js2QLtUZ0aAHoIaoKdcNUDXOfpEn6dhWVCgUACp-M-5a1ohM9PWPva7yQ2i1ll3e1qeziAh_joV6OWm8XIaZjac9mAf0oxKTLr1y0cVo58GaVAkU6XqkhkkNWezg0gHNQaRM_6QeHYkc_7fOFrNsVDq0dib3DRJA1_wWV_rtvQMf9QAvk41NwQR1hiSi3R1qTf7LycP51hIEj8X-Czy3EqXVAb7Y58EXRuIL00AS6xHE.ZWU0ZWIzMTktMWRhNi00Mzg0LTllMzYtNzlmMDU3MjRmYTkx";
    const region = "us-east-1";
    const mapName = "AddressSearchDisplay";
    const styleUrl = `https://maps.geo.${region}.amazonaws.com/maps/v0/maps/${mapName}/style-descriptor?key=${apiKey}`;
    const map = new maplibregl.Map({
      container: "scs-live-map",
      style: styleUrl,
      center: [this.coordinates[0], this.coordinates[1]],
      zoom: 12,
    });
    this.map = map;
  },
  components: {},
  props: {
    coordinates: {
      type: Array,
      required: false,
      default() {
        return [-90.19282698174605, 38.63138200185793];
      },
    },
  },
  watch: {
    coordinates(newValue, oldValue) {
      this.map.setCenter(newValue);
    },
  },
  emits: {},
};
</script>
<style scoped>
#map-wrapper {
  height: 100%;
  width: 100%;
  max-width: 400px;
  max-height: 400px;
  border-radius: 10px;
  overflow: hidden;
}
#scs-live-map {
  /* height: 110%; */
  /* width: 100%; */
  background-color: #f2efeb;
}
</style>