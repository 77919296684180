<template>
  <div class="scs-button">
    <p v-if="!processing">
      {{ text }}
    </p>
    <svg
      v-if="processing"
      class="spinning"
      width="50"
      height="50"
      viewBox="0 0 50 50"
    >
      <path
        fill="currentColor"
        d="M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z"
      ></path>
    </svg>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  computed: {},
  methods: {},
  created() {},
  components: {},
  props: {
    text: {
      type: String,
    },
    processing: {
      type: Boolean,
    },
  },
  emits: {},
};
</script>
<style scoped>
.scs-button {
  width: fit-content;
  padding: 3px;
  background-color: #292929;
  color: #f2efeb;
  border-radius: 7px;
  filter: drop-shadow(0px 2px 3px #292929);
  cursor: pointer;
}

.scs-button:hover {
  padding: 2px;
  font-weight: 600;
  filter: drop-shadow(0px 3px 3px #292929);
}

p {
  margin: 10px;
}

.spinning {
  animation-name: spin;
  animation-duration: 1s;
  animation-direction: normal;
  animation-iteration-count: infinite;
}
svg {
  height: 15px;
  width: 15px;
  margin: 5px 30px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>