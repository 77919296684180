<template>
  <div :class="`home-style tier-${tier}`">
    <h1 v-if="tier === 1">
      {{ style.name }}
    </h1>
    <img :src="require('@/assets/' + style.picture)" alt="" />
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  computed: {},
  methods: {},
  created() {},
  components: {},
  props: {
    tier: {
      type: Number,
      default: 0,
    },
    style: {
      type: Object,
    },
  },
  emits: {},
};
</script>
<style scoped>
.home-style {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
img {
  height: 100px;
  width: 100px;
  object-fit: cover;
  border-radius: 50%;
}

h1 {
  color: #f2efeb;
  text-transform: uppercase;
  font-family: Bodoni, serif;
}

.tier-1 > img {
  height: 150px;
  width: 150px;
  margin-bottom: 75px;
}

.tier-1 {
  max-width: 150px;
}

.tier-2 img {
  opacity: 0.75;
  filter: blur(1.5px);
}

.tier-3 > img {
  height: 50px;
  width: 50px;
  opacity: 0.5;
  filter: blur(3px);
}
@media (min-width: 1200px) {
  img {
    height: 200px;
    width: 200px;
  }

  .tier-1 > img {
    height: 300px;
    width: 300px;
  }
  .tier-1 {
    max-width: 300px;
  }

  .tier-2 img {
    opacity: 0.75;
    filter: blur(1.5px);
  }

  .tier-3 > img {
    height: 100px;
    width: 100px;
    opacity: 0.5;
    filter: blur(3px);
  }
}
</style>