<template>
  <div id="home-callout-section">
    <div id="resident-expert" class="callout">
      <div id="resident-expert-heading" class="callout-heading">
        <h1>St. Louis'</h1>
        <h1 class="alternate">Resident</h1>
        <h1>Real Estate</h1>
        <h1 class="alternate">Expert</h1>
      </div>

      <p class="copy">
        There really isn’t anything in St. Louis Real Estate that I haven’t
        seen. I have extensive personal experience with everything from single
        family homes, to downtown lofts, to farms. Foreclosures to luxury
        high-rises, I’ve got it handled.
      </p>
    </div>
    <div id="investors-mind" class="callout">
      <div id="investors-mind-heading" class="callout-heading">
        <h1>Realtor's Skill</h1>
        <h1 class="alternate">Investor's Mind</h1>
      </div>

      <p class="copy">
        Growing up, my parents had a large rental portfolio. Later, my husband
        and I have rehabbed four homes and built two new houses. Not everything
        is for profit, but if you want someone to look out for your long-term
        interest, look no further.
      </p>
    </div>
    <div id="know-it-all" class="callout">
      <div id="know-it-all-heading" class="callout-heading">
        <h1>Neighborhood</h1>
        <h1 class="alternate">Know it all</h1>
      </div>
      <div id="know-it-all-copy">
        <p class="copy">
          The Saint Louis area is a beautiful tapestry of diverse neighborhoods
          and cultures. My husband and I have had the pleasure of getting to
          know each of these through different ventures.
        </p>

        <p class="copy">
          From the downtown neighborhoods, West, South, and North County, and
          even further out to Saint Charles, Warren, Jefferson, Franklin,
          Lincoln counties and beyond, I have a lifetime of experience in these
          areas and can help you, whether buying or selling.
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped>
#home-callout-section {
  padding: 25px;
}
.callout {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.callout-heading {
  padding: 50px;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: visible;
}

h1 {
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: 300;
  margin: 10px;
}

.alternate {
  font-family: Bodoni Moda, serif;
  font-weight: 600;
}

.copy {
  text-align: center;
  font-size: 1rem;
  line-height: 1.5rem;
}

#resident-expert-heading > .alternate {
  text-align: right;
}

#investors-mind {
  flex-direction: column;
}

#investors-mind-heading > h1 {
  text-align: center;
}
#investors-mind-heading > .alternate {
  padding: 10px 20px;
  box-sizing: border-box;
  color: #f2efeb;
  background-color: #292929;
}

#know-it-all-heading {
  text-align: center;
  padding-bottom: 0;
}

#know-it-all {
  flex-direction: column;
  justify-content: center;
}

#know-it-all-copy {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#know-it-all-copy > .copy {
  text-align: left;
}

@media (min-width: 1000px) {
  #home-callout-section {
    padding: 100px;
  }
  .callout {
    flex-direction: row;
  }

  h1 {
    font-size: 2rem;
  }

  .copy {
    margin: 50px;
    font-size: 1rem;
    line-height: 2rem;
    max-width: 30vw;
  }

  #investors-mind {
    flex-direction: row-reverse;
  }

  #know-it-all-copy {
    flex-direction: row;
  }
}

@media (min-width: 1200px) {
  #investors-mind {
    flex-direction: row-reverse;
  }
}
</style>