<template>
  <div id="sellers-page-hero">
    <div id="sellers-page-hero-headline">
      <h1>
        Dreaming of a <br />
        new home?
      </h1>
    </div>
    <img src="../../assets/stock-house.jpg" alt="house" />
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
#sellers-page-hero {
  height: 80vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#sellers-page-hero-headline {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-items: flex-end;
  margin: 0 50px;
}
h1 {
  font-size: 2.5rem;
  font-weight: 300;
  font-family: Bodoni;
  text-transform: uppercase;
  text-align: left;
}

img {
  width: 75%;
  max-width: 400px;
  max-height: 500px;
  height: 75%;
  object-fit: cover;
  border-radius: 30px;
  opacity: 0.7;
  filter: drop-shadow(0px 2px 4px #292929);
}
@media (min-width: 900px) {
  #sellers-page-hero {
    flex-direction: row;
  }
  h1 {
    font-size: 3rem;
    text-align: right;
  }
}
@media (min-width: 1100px) {
  img {
    width: 400px;
    height: 500px;
  }
}
</style>