<template>
  <div id="bedroom-count-numbers">
    <CountButton
      v-for="count in counts"
      :key="count"
      :count="count"
      :selected="selected === count"
      @click="selectCount(count)"
    />
  </div>
</template>
<script>
import CountButton from "./CountButton.vue";
export default {
  data() {
    return {
      selected: undefined,
      counts: ["1", "2", "3", "4+"],
    };
  },
  computed: {},
  methods: {
    selectCount(count) {
      this.selected = count;
      this.$emit("bedroom-input", count);
    },
  },
  components: {
    CountButton,
  },
  emits: { "bedroom-input": null },
};
</script>
<style scoped>
#bedroom-count-numbers {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
</style>