<template>
  <div id="form-success-slide">
    <h1>Thanks!</h1>
    <h3>I'll be with you shortly.</h3>
  </div>
</template>
<style scoped>
#form-success-slide {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
h1 {
  font-family: Bodoni, serif;
  font-size: 3rem;
  font-weight: 600;
  color: #f2efeb;
  margin: 0;
}
h3 {
  z-index: 2;
  font-size: 1.5rem;
  background-color: #f2efeb;
  color: #292929;
  padding: 10px;
  margin-top: -5px;
  font-weight: 400;
}

h1,
h3 {
  text-transform: uppercase;
  text-align: center;
}
@media (min-width: 800px) {
  h1 {
    font-size: 8rem;
    margin: 0;
  }
  h3 {
    font-size: 2.25rem;
    padding: 10px;
    margin-top: -5px;
  }
}
</style>