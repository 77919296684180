<template>
  <div id="lauryn-coleman-footer">
    <div class="footer-stack">
      <p>Copyright, 2023, Lauryn Coleman</p>
      <p>Lauryn Coleman</p>
      <p>314-610-7887</p>
      <p>lauryn@thelauryncoleman.com</p>
    </div>
    <div class="footer-stack">
      <p>C4 Realty Group</p>
      <p>636-543-5943</p>
    </div>
    <p>Made with ❤️ by Simple City Software</p>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  computed: {},
  methods: {},
  created() {},
  components: {},
  props: {},
  emits: {},
};
</script>
<style scoped>
#lauryn-coleman-footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  padding: 20px;
  box-sizing: border-box;
}
.footer-stack {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
}

p {
  margin: 3px;
  font-size: 0.75rem;
}
@media (min-width: 650px) {
  #lauryn-coleman-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    padding: 20px;
    box-sizing: border-box;
  }
  .footer-stack {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
  }

  p {
    margin: 3px;
    font-size: 0.75rem;
  }
}
</style>