<template>
  <div id="buyer-form-home-style">
    <HomeStyle :tier="3" :distant="true" :style="farLeftStyle" />
    <HomeStyle :tier="2" :style="leftStyle" />
    <svg
      width="20"
      height="34"
      viewBox="0 0 20 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      @click="back"
    >
      <path
        d="M17.5598 32L2.55981 17L17.5598 2"
        stroke="#F2EFEB"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    <HomeStyle :tier="1" :style="centerStyle" />
    <svg
      width="20"
      height="34"
      viewBox="0 0 20 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      @click="next"
    >
      <path
        d="M2.55957 32L17.5596 17L2.55957 2"
        stroke="#F2EFEB"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    <HomeStyle :tier="2" :style="rightStyle" />
    <HomeStyle :tier="3" :style="farRightStyle" />
  </div>
</template>
<script>
import HomeStyle from "./HomeStyle.vue";
export default {
  data() {
    return {
      focusIndex: 0,
      styles: [
        {
          name: "Farm",
          picture: "farmhouse.jpg",
        },
        {
          name: "Loft",
          picture: "loftbuilding.jpg",
        },
        {
          name: "Suburban",
          picture: "subdivisionhome.jpg",
        },
        {
          name: "Cabin",
          picture: "logcabin.jpg",
        },
        {
          name: "Townhome",
          picture: "townhome.jpg",
        },
      ],
    };
  },
  computed: {
    farLeftStyle() {
      if (this.focusIndex === 0) {
        return this.styles[this.styles.length - 2];
      }
      if (this.focusIndex === 1) {
        return this.styles[this.styles.length - 1];
      }
      return this.styles[this.focusIndex - 2];
    },
    leftStyle() {
      if (this.focusIndex === 0) {
        return this.styles[this.styles.length - 1];
      }
      return this.styles[this.focusIndex - 1];
    },
    centerStyle() {
      return this.styles[this.focusIndex];
    },
    rightStyle() {
      if (this.focusIndex === this.styles.length - 1) {
        return this.styles[0];
      } else {
        return this.styles[this.focusIndex + 1];
      }
    },
    farRightStyle() {
      if (this.focusIndex === this.styles.length - 2) {
        return this.styles[0];
      } else if (this.focusIndex === this.styles.length - 1) {
        return this.styles[1];
      }
      {
        return this.styles[this.focusIndex + 2];
      }
    },
  },
  methods: {
    back() {
      if (this.focusIndex === 0) {
        this.focusIndex = this.styles.length - 1;
      } else {
        this.focusIndex = this.focusIndex - 1;
      }
    },
    next() {
      if (this.focusIndex === this.styles.length - 1) {
        this.focusIndex = 0;
      } else {
        this.focusIndex = this.focusIndex + 1;
      }
    },
  },
  components: {
    HomeStyle,
  },
  emits: {
    "select-style": null,
  },
};
</script>
<style scoped>
#buyer-form-home-style {
  width: 100%;
  min-width: 800px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

svg {
  cursor: pointer;
}
@media (min-width: 800px) {
  #buyer-form-home-style {
    width: 100%;
  }
}
</style>