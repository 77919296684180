<template>
  <div id="hero-section">
    <img src="@/assets/laurynpic.png" alt="lauryn-coleman" />
    <div id="hero-content">
      <div id="greeting">
        <h1>Hi!</h1>
        <h3>I'm Lauryn.</h3>
      </div>
      <div id="hero-copy">
        <h5>
          Saint Louis Area Realtor, dog mom, wife, sports fan, investor,
          business owner.
        </h5>
        <h4 class="callout">in that order</h4>
      </div>
    </div>
  </div>
</template>

<style scoped>
#hero-section {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90vh;
  padding: 50px;
  box-sizing: border-box;
}

img {
  margin-left: 0px;
  border: 5px solid #292929;
  height: 200px;
  width: 200px;
  border-radius: 55%;
  object-fit: cover;
  object-position: top;
  background-color: #f2efeb;
  padding-top: 10px;
  aspect-ratio: 1 / 1;
}

#hero-content {
  display: flex;
  flex-direction: column;
  text-align: right;
  justify-content: center;
}

h1 {
  font-family: Bodoni Moda, Serif;
  font-weight: 500;
  font-size: 5rem;
}
h3 {
  font-size: 20px;
}
h1,
h3 {
  text-transform: uppercase;
  font-weight: 500;
  margin: 0px;
}

#hero-copy {
  height: fit-content;
  border-left: 3px solid #292929;
  padding: 20px 0 0 20px;
  margin-left: 20px;
}

h5 {
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 500;
  white-space: pre-wrap;
}

.callout {
  width: fit-content;
  margin: 0;
  margin-left: -20px;
  padding: 20px;
  color: #f2efeb;
  background-color: #292929;
}
@media (min-width: 700px) {
  #hero-section {
    flex-direction: row;
  }
  img {
    height: 250px;
    width: 250px;
  }

  #hero-content {
    flex-direction: row;
    width: 40%;
    margin: 10%;
  }

  h1 {
    font-size: 6rem;
    /* height: 150px; */
  }
  h3 {
    font-size: 1.25rem;
  }

  h5 {
    font-size: 1rem;
    text-align: left;
  }
}
@media (min-width: 900px) {
  img {
    height: 250px;
    width: 250px;
  }
}
</style>