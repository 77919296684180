import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import BuyerView from '../views/BuyerView.vue';
import SellerView from '../views/SellerView.vue';
import ContactView from '../views/ContactView.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/sellers',
    name: 'sellers',
    component: SellerView
  },
  {
    path: '/buyers',
    name: 'buyers',
    component: BuyerView
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
