<template>
  <div :class="{ 'scs-address-list-item': true, selected: selected }">
    <p class="address">{{ address }}</p>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  computed: {},
  methods: {},
  created() {},
  components: {},
  props: {
    address: null,
    selected: {
      type: Boolean,
      default: false,
    },
  },
  emits: {},
};
</script>
<style scoped>
.scs-address-list-item {
  color: #f2efeb;
  border: 1px solid #f2efeb;
  background-color: #292929;
  padding: 15px;
  text-transform: uppercase;
  font-weight: 500;
  border-radius: 8px;
  margin: 5px 0;
  overflow: hidden;
  cursor: pointer;
}
.selected {
  background-color: #f2efeb;
  color: #292929;
}
</style>