<template>
  <div id="sellers-value-details">
    <h1>Special value I provide</h1>
    <div id="details">
      <div class="detail" v-for="(detail, index) in details" :key="index">
        <h5>{{ detail.headline }}:</h5>
        <p>{{ detail.body }}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      details: [
        {
          headline: "Experience",
          body: "I have extensive experience in selling homes of my own and others!I have a keen eye for improvements and ideas to get you ready for market.",
        },
        {
          headline: "Preparations",
          body: "I have a keen eye for improvements and ideas to get you ready for market.",
        },
        {
          headline: "Negotiations",
          body: "When offers come in, I organize them so you can compare them and make the clearest decision. ",
        },
        {
          headline: "Marketings",
          body: "My business experience gives me unique talents in social media and traditional marketing that I can use for your home!",
        },
        {
          headline: "Content",
          body: "Today’s buyers live for content. My team has the ability to put your house on their screens with professional photography and drone footage.",
        },
      ],
    };
  },
  computed: {},
  methods: {},
  created() {},
  components: {},
  props: {},
  emits: {},
};
</script>
<style scoped>
#sellers-value-details {
  display: flex;
  flex-direction: column;
}

#details {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.detail {
  width: 90%;
  box-sizing: border-box;
  text-align: center;
}

h1 {
  width: 100%;
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: 500;
  text-align: center;
}

h5 {
  width: 100%;
  font-size: 1.25rem;
  font-family: Bodoni, serif;
}
p {
  font-size: 1rem;
}
@media (min-width: 800px) {
  #details {
    flex-direction: row;
    align-items: flex-start;
  }

  .detail {
    padding: 12.5px;
    width: 40%;
    text-align: left;
  }

  h1 {
    font-size: 2rem;
  }

  p {
    font-size: 1rem;
  }
}
</style>