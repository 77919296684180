<template>
  <div id="buyers-contact-form">
    <h2 v-html="heading"></h2>
    <component
      :is="step"
      @select-style="selectStyle"
      @bedroom-input="bedroomInput"
      @bathroom-input="bathroomInput"
      @additional-detail-input="additionalDetailInput"
      @first-name-input="firstNameInput"
      @last-name-input="lastNameInput"
      @phone-number-input="phoneNumberInput"
      @email-address-input="emailAddressInput"
    />
    <ButtonVue
      v-if="step !== 'FormSuccess'"
      id="form-button"
      :processing="processing"
      :text="buttonText"
      @click="next"
    />
  </div>
</template>

<script>
import AdditionalDetailsVue from "@/components/Shared/AdditionalDetails.vue";
import ButtonVue from "@/components/Shared/Button.vue";
import ContactFormPersonVue from "../../Shared/ContactFormPerson.vue";
import FormSuccessVue from "../../Shared/FormSuccess.vue";
import BathroomCountVue from "./BathroomCount.vue";
import BedroomCountVue from "./BedroomCount.vue";
import HomeStyles from "./HomeStyles.vue";
import FormSuccess from "../../Shared/FormSuccess.vue";

import axios from "axios";

export default {
  data() {
    return {
      step: "HomeStyles",
      processing: false,
      form: {
        home_style: "Farm",
        bedroom_count: undefined,
        bathroom_count: undefined,
        message: undefined,
        contact_email: undefined,
        contact_first_name: undefined,
        contact_last_name: undefined,
        contact_phone_number: undefined,
      },
    };
  },
  computed: {
    buttonText() {
      switch (this.step) {
        case "HomeStyles":
          return "Start journey";
        case "BedroomCountVue":
        case "BathroomCountVue":
        case "AdditionalDetailsVue":
          return "Next";
        case "ContactFormPersonVue":
          return "Submit";
      }
    },
    heading() {
      switch (this.step) {
        case "HomeStyles":
          return `What's your <span style="color: #292929; background-color: #f2efeb; padding:10px; font-family: Bodoni, serif;">style</span>?`;
        case "BedroomCountVue":
          return "With how many bedrooms?";
        case "BathroomCountVue":
          return "and bathrooms?";
        case "AdditionalDetailsVue":
          return "Any additional details";
        case "ContactFormPersonVue":
          return "How can I send them to you?";
      }
    },
  },
  methods: {
    firstNameInput(val) {
      this.form.contact_first_name = val;
    },
    lastNameInput(val) {
      this.form.contact_last_name = val;
    },
    emailAddressInput(val) {
      this.form.contact_email = val;
    },
    phoneNumberInput(val) {
      this.form.contact_phone_number = val;
    },
    selectStyle(val) {
      this.form.home_style = val;
    },
    bedroomInput(val) {
      this.form.bedroom_count = val;
    },
    bathroomInput(val) {
      this.form.bathroom_count = val;
    },
    additionalDetailInput(val) {
      this.form.message = val;
    },
    async next() {
      switch (this.step) {
        case "HomeStyles":
          this.step = "BedroomCountVue";
          break;
        case "BedroomCountVue":
          this.step = "BathroomCountVue";
          break;
        case "BathroomCountVue":
          this.step = "AdditionalDetailsVue";
          break;
        case "AdditionalDetailsVue":
          this.step = "ContactFormPersonVue";
          break;
        case "ContactFormPersonVue":
          this.processing = true;
          await axios
            .put(
              "https://8vti1zuhk6.execute-api.us-east-1.amazonaws.com/forms/buyer",
              this.form
            )
            .then((res) => {
              console.log(res);
            })
            .catch((e) => {
              console.log(e);
            });
          this.step = "FormSuccess";
          setTimeout(() => {
            this.$router.push("/");
            console.log(document);
            document.body.scrollTop = document.documentElement.scrollTop = 0;
          }, 2000);
          break;
      }
    },
  },
  created() {},
  components: {
    AdditionalDetailsVue,
    ContactFormPersonVue,
    HomeStyles,
    FormSuccessVue,
    BathroomCountVue,
    BedroomCountVue,
    FormSuccess,
    ButtonVue,
  },
  props: {},
  emits: {},
};
</script>
<style scoped>
#buyers-contact-form {
  background-color: #292929;
  height: 95vh;
  padding: 50px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
h2 {
  color: #f2efeb;
  text-align: center;
  font-size: 2rem;
  margin: 0;
  text-transform: uppercase;
}

#form-button {
  color: #292929;
  background-color: #f2efeb;
  filter: drop-shadow(0 2px 3px #f2efeb);
}
span.negative {
  color: #292929 !important;
  background-color: #f2efeb;
}
@media (min-width: 800px) {
  #buyers-contact-form {
    padding: 50px;
  }
  h2 {
    font-size: 2.5rem;
  }
}
</style>