<template>
  <div id="buying-selling-callouts">
    <router-link to="/sellers" class="card">
      <h1>Selling</h1>
      <svg
        width="167"
        height="233"
        viewBox="0 0 167 233"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="127.384" width="24.9931" height="233" rx="5" fill="#292929" />
        <mask id="path-2-inside-1_6_46" fill="white">
          <rect
            x="14.5121"
            y="45.1487"
            width="100.779"
            height="119.322"
            rx="5"
          />
        </mask>
        <rect
          x="14.5121"
          y="45.1487"
          width="100.779"
          height="119.322"
          rx="5"
          stroke="#292929"
          stroke-width="16"
          mask="url(#path-2-inside-1_6_46)"
        />
        <rect
          y="34.6677"
          width="24.9931"
          height="166.889"
          rx="5"
          transform="rotate(-90 0 34.6677)"
          fill="#292929"
        />
        <line
          x1="34.4429"
          y1="31.4429"
          x2="34.4429"
          y2="49.9861"
          stroke="#292929"
          stroke-width="6"
        />
        <line
          x1="101.36"
          y1="31.4429"
          x2="101.36"
          y2="49.9861"
          stroke="#292929"
          stroke-width="6"
        />
      </svg>
    </router-link>
    <router-link to="/buyers" class="card">
      <h1>Buying</h1>
      <svg
        width="227"
        height="276"
        viewBox="0 0 227 276"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M39.8888 95.9734H222.204L204.259 182.106L56.7565 182.824L39.8888 95.9734Z"
          fill="#292929"
        />
        <path
          d="M182.872 227.416H77.261C73.1743 227.415 69.2173 225.98 66.0801 223.361C62.9428 220.742 60.8242 217.105 60.0937 213.084L54.7667 183.789M182.872 227.416C170.824 227.416 161.058 237.182 161.058 249.23C161.058 261.277 170.824 271.043 182.872 271.043C194.919 271.043 204.685 261.277 204.685 249.23C204.685 237.182 194.919 227.416 182.872 227.416ZM4 52.907H23.6868C25.7302 52.9073 27.7087 53.6247 29.2773 54.9342C30.8459 56.2438 31.9052 58.0623 32.2705 60.0727L38.9005 96.5342M38.9005 96.5342H42.1766C112.455 96.5342 222.136 96.5342 222.136 96.5342L208.874 169.457C208.143 173.478 206.024 177.115 202.887 179.734C199.75 182.353 195.793 183.788 191.706 183.789H54.7667M38.9005 96.5342L54.7667 183.789M79.0709 70.2902L109.208 20.0484C109.542 19.4909 109.988 19.0092 110.519 18.6342C111.049 18.2592 111.653 17.9992 112.289 17.8707L174.96 4.09128C176.099 3.86264 177.282 4.06708 178.279 4.66461C179.275 5.26214 180.012 6.20978 180.347 7.3222L197.694 69.1005C197.882 69.7229 197.938 70.3778 197.858 71.023C197.777 71.6682 197.563 72.2895 197.229 72.8471L183.085 96.3323M67.0681 63.0904L120.279 95.9735M140.377 96.3323L151.884 75.8828C152.52 74.8217 152.709 73.5512 152.409 72.3508C152.109 71.1504 151.344 70.1185 150.283 69.482L134.279 59.8822C133.218 59.2457 131.947 59.0568 130.747 59.357C129.546 59.6573 128.515 60.4221 127.878 61.4832L111.078 89.4898M99.9799 249.23C99.9799 261.277 90.2136 271.043 78.1663 271.043C66.119 271.043 56.3527 261.277 56.3527 249.23C56.3527 237.182 66.119 227.416 78.1663 227.416C90.2136 227.416 99.9799 237.182 99.9799 249.23Z"
          stroke="#292929"
          stroke-width="8"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </router-link>
  </div>
</template>
<style scoped>
#buying-selling-callouts {
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.card {
  width: 100%;
  height: 200px;
  margin: 10px;
  border-radius: 7px;
  background-color: #f2efeb;
  filter: drop-shadow(0px 2px 4px #292929);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

h1 {
  text-transform: uppercase;
  font-size: 2rem;
}

svg {
  max-width: 30%;
  max-height: 70%;
}
@media (min-width: 1100px) {
  #buying-selling-callouts {
    padding: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }

  .card {
    width: 30%;
    max-height: 600px;
    height: 500px;
    border-radius: 7px;
    background-color: #f2efeb;
    filter: drop-shadow(0px 2px 4px #292929);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }

  h1 {
    text-transform: uppercase;
    font-size: 2.5rem;
  }
}
</style>