<template>
  <div id="sellers-contact-form">
    <h2>{{ heading }}</h2>
    <component
      :is="step"
      :selectedAddress="form.address"
      @select-address="selectAddress"
      @additional-detail-input="additionalDetailInput"
      @first-name-input="firstNameInput"
      @last-name-input="lastNameInput"
      @phone-number-input="phoneNumberInput"
      @email-address-input="emailAddressInput"
    />
    <ButtonVue
      v-if="step !== 'FormSuccess'"
      id="form-button"
      :processing="processing"
      :text="buttonText"
      @click="next"
    />
  </div>
</template>
<script>
import ContactFormPersonVue from "../../Shared/ContactFormPerson.vue";
import AdditionalDetailsVue from "../../Shared/AdditionalDetails.vue";
import AddressSearchVue from "./AddressSearch.vue";
import ButtonVue from "@/components/Shared/Button.vue";
import FormSuccess from "../../Shared/FormSuccess.vue";
import axios from "axios";

export default {
  data() {
    return {
      step: "AddressSearchVue",
      processing: false,
      form: {
        address: undefined,
        message: undefined,
        contact_first_name: undefined,
        contact_last_name: undefined,
        contact_email: undefined,
        contact_phone_number: undefined,
      },
    };
  },
  methods: {
    async next() {
      switch (this.step) {
        case "AddressSearchVue":
          this.step = "AdditionalDetailsVue";
          break;
        case "AdditionalDetailsVue":
          this.step = "ContactFormPersonVue";
          break;
        case "ContactFormPersonVue":
          this.processing = true;
          await axios
            .put(
              "https://8vti1zuhk6.execute-api.us-east-1.amazonaws.com/forms/seller",
              this.form
            )
            .then((res) => {
              console.log(res);
            })
            .catch((e) => {
              console.log(e);
            });
          this.step = "FormSuccess";
          setTimeout(() => {
            this.$router.push("/");
            document.body.scrollTop = document.documentElement.scrollTop = 0;
          }, 2000);
          this.processing = false;
          break;
      }
    },
    selectAddress(address) {
      this.form.address = address;
    },
    additionalDetailInput(val) {
      this.form.message = val;
    },
    firstNameInput(val) {
      this.form.contact_first_name = val;
    },
    lastNameInput(val) {
      this.form.contact_last_name = val;
    },
    phoneNumberInput(val) {
      this.form.contact_phone_number = val;
    },
    emailAddressInput(val) {
      this.form.contact_email = val;
    },
  },
  computed: {
    heading() {
      switch (this.step) {
        case "AddressSearchVue":
          return "Where's your place?";
        case "AdditionalDetailsVue":
          return "Any additional details?";
        case "ContactFormPersonVue":
          return "How can I reach you?";
      }
    },
    buttonText() {
      switch (this.step) {
        case "AddressSearchVue":
          if (!this.form.address) {
            return "Skip this part";
          }
          return "That's it!";
        case "AdditionalDetailsVue":
          return "Next?";
        case "ContactFormPersonVue":
          return "Submit!";
      }
    },
  },
  components: {
    ContactFormPersonVue,
    ButtonVue,
    AdditionalDetailsVue,
    AddressSearchVue,
    FormSuccess,
  },
};
</script>
<style scoped>
#sellers-contact-form {
  background-color: #292929;
  min-height: 95vh;
  padding: 50px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
h2 {
  color: #f2efeb;
  text-align: center;
  font-size: 2rem;
  margin: 0;
  text-transform: uppercase;
}

#form-button {
  color: #292929;
  background-color: #f2efeb;
  filter: drop-shadow(0 2px 3px #f2efeb);
}
span.negative {
  color: #292929 !important;
  background-color: #f2efeb;
}
@media (min-width: 800px) {
  #sellers-contact-form {
    padding: 50px;
  }
  h2 {
    font-size: 2.5rem;
  }
}
</style>