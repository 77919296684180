<template>
  <div id="sellers-value-details">
    <h1>Special value I provide</h1>
    <div id="details">
      <div class="detail" v-for="(detail, index) in details" :key="index">
        <h5>{{ detail.headline }}:</h5>
        <p>{{ detail.body }}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      details: [
        {
          headline: "Cost",
          body: "It costs you $0 to buy a home with me!",
        },
        {
          headline: "Repairs",
          body: "I can help you to know what issues can be fixed and which ones may not.",
        },
        {
          headline: "Vision",
          body: "As a real estate investor, myself, I can help you see a home's potential!",
        },
        {
          headline: "Areas",
          body: "I have expansive knowledge of St. Louis and the surrounding areas!",
        },
        {
          headline: "Availbility",
          body: "My team and I are ready and willing to show you the home of your dreams!",
        },
      ],
    };
  },
};
</script>
<style scoped>
#sellers-value-details {
  display: flex;
  flex-direction: column;
}

#details {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.detail {
  width: 90%;
  box-sizing: border-box;
  text-align: center;
}

h1 {
  width: 100%;
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: 500;
  text-align: center;
}

h5 {
  width: 100%;
  font-size: 1.25rem;
  font-family: Bodoni, serif;
}
p {
  font-size: 1rem;
}
@media (min-width: 800px) {
  #details {
    flex-direction: row;
    align-items: flex-start;
  }

  .detail {
    padding: 12.5px;
    width: 40%;
    text-align: left;
  }

  h1 {
    font-size: 2rem;
  }

  p {
    font-size: 1rem;
  }
}
</style>