<template>
  <div class="simple-city-software-input-with-optional-heading">
    <h5 v-if="heading">{{ heading }}:</h5>
    <input
      v-if="!textarea"
      :type="type"
      :disabled="disabled"
      :placeholder="placeholder"
      v-model="data"
      @blur="blur()"
      @focus="focus"
    />
    <textarea
      v-if="textarea"
      :disabled="disabled"
      :placeholder="placeholder"
      v-model="data"
      @blur="blur()"
      @focus="focus"
    />
  </div>
</template>
<script>
export default {
  data() {
    return {
      value: undefined,
    };
  },
  computed: {
    data: {
      get() {
        return this.value;
      },
      set(e) {
        this.value = e;
        this.$emit("scs-input", this.value);
      },
    },
  },
  methods: {
    blur() {
      this.$emit("scs-blur");
    },
    focus($event) {
      this.$emit("scs-focus", $event);
    },
  },
  created() {
    if (this.initialValue) {
      this.value = this.initialValue;
    }
  },
  watch: {
    initialValue() {
      this.value = this.initialValue;
    },
  },

  components: {},
  // todo : create number input for better styling
  emits: ["scs-input", "scs-blur", "scs-focus"],
  props: {
    heading: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    initialValue: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    type: {
      type: String,
      required: false,
      default: "text",
    },
    error: {
      type: Boolean,
      required: false,
      default: false,
    },
    suffix: {
      type: String,
      required: false,
    },
    prefix: {
      type: String,
      required: false,
    },
    textarea: {
      type: Boolean,
    },
  },
};
</script>
<style scoped>
.simple-city-software-input-with-optional-heading {
  display: flex;
  width: fit-content;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}

h5 {
  font-size: 0.85rem;
  margin: 10px;
  width: 90%;
  text-align: left;
  color: #f2efeb;
}

p {
  padding: 0;
  max-width: 75%;
  overflow-wrap: break-word;
  text-align: right;
  margin: none;
}
input {
  text-align: center;
}
input,
textarea {
  border: 1px solid #f2efeb;
  background-color: #292929;
  border-radius: 4px;
  height: auto;
  display: flex;
  justify-content: space-between;
  font-size: 0.8rem;
  background-color: #292929;
  padding: 10px;
  color: #f2efeb;
  outline: none;
  font-family: Montserrat, sans-serif;
  width: 100%;
}

.input:has(input:focus),
textarea:focus {
  outline: none;
  filter: drop-shadow(0 0 4px #f2efeb);
}
.error {
  border: 1px solid red;
}
</style>
