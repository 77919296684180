<template>
  <div id="seller-form-address-search">
    <div id="search-column">
      <Input
        id="address-search-input"
        @scs-input="searchTermInput"
        heading="Property address"
        placeholder="Start Typing..."
      />
      <transition-group id="results-window" tag="div" name="list">
        <AddressListItemVue
          class="address-list-item"
          :selected="
            selectedAddress ? result.PlaceId === selectedAddress.PlaceId : false
          "
          v-for="(result, index) in searchResults"
          :key="index"
          :address="result.Text"
          @click="selectAddress(result)"
        />
      </transition-group>
      <svg
        v-if="processing"
        class="spinning"
        width="50"
        height="50"
        viewBox="0 0 50 50"
      >
        <path
          fill="currentColor"
          d="M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z"
        ></path>
      </svg>
    </div>
    <LiveMap
      :coordinates="selectedAddress ? selectedAddress.Geometry : undefined"
      :address="chosenAddress"
    />
  </div>
</template>
<script>
import Input from "@/components/Shared/Input.vue";
import AddressListItemVue from "../../Shared/AddressListItem.vue";
import LiveMap from "@/components/Shared/LiveMap.vue";

import axios from "axios";

export default {
  data() {
    return {
      timer: undefined,
      searchTerm: undefined,
      processing: false,
      searchResults: [],
    };
  },
  computed: {},
  methods: {
    async searchTermInput(value) {
      this.loading = true;
      this.searchTerm = value;
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(async () => {
        this.processing = true;
        await axios
          .put(
            "https://8vti1zuhk6.execute-api.us-east-1.amazonaws.com/location/search/address",
            { text: value }
          )
          .then((res) => {
            this.searchResults = res.data;
          });
        this.processing = false;
      }, 500);
    },
    async selectAddress(address) {
      await axios
        .get(
          `https://8vti1zuhk6.execute-api.us-east-1.amazonaws.com/location/search/place/${address.PlaceId}`
        )
        .then((res) => {
          console.log(res);
          this.$emit("select-address", {
            PlaceId: address.PlaceId,
            Address: res.data.Place.Label,
            Geometry: res.data.Place.Geometry.Point,
          });
        });
      console.log(address);
    },
  },
  created() {},
  components: {
    Input,
    AddressListItemVue,
    LiveMap,
  },
  props: {
    selectedAddress: null,
  },
  emits: {},
};
</script>
<style scoped>
#seller-form-address-search {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  margin-bottom: 5px;
}

#search-column {
  margin: 25px;
  width: 100%;
  max-width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
#results-window {
  max-width: calc(100% - 45px);
  /* overflow: scroll; */
}

#address-search-input {
  max-width: 100%;
  /* margin-left: 15px; */
  width: calc(100% - 30px);
  /* width: 100%; */
}
.address-list-item {
  max-width: 100%;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
@media (min-width: 950px) {
  #seller-form-address-search {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
  }

  #search-column {
    max-width: 400px;
    width: 100%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
  }
  #results-window {
    max-height: calc(100% - 45px);
    overflow: scroll;
  }

  #address-search-input {
    max-width: 100%;
    /* margin-left: 15px; */
    width: calc(100% - 30px);
  }
}
.spinning {
  animation-name: spin;
  animation-duration: 1s;
  animation-direction: normal;
  animation-iteration-count: infinite;
}
svg {
  height: 15px;
  width: 15px;
  margin: 30px 30px;
  color: #f2efeb;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>